/**
 * Test for elements in view
 */
export default function isElementInView(element, fullyInView) {
  var pageTop = $(window).scrollTop();
  var pageBottom = pageTop + $(window).height();
  var elementTop = $(element).offset().top + 1;
  var elementHeight = $(element).height();
  var elementBottom = elementTop + elementHeight - 2;

  if (fullyInView === true) {
    return (
      $(element).is(":visible") &&
      pageTop < elementTop &&
      pageBottom > elementBottom
    );
  } else if (fullyInView === "viewed") {
    return elementTop <= pageBottom;
  } else {
    return elementTop <= pageBottom && elementBottom >= pageTop;
  }
}
