function commerceAmazon() {
	let queryString = "tag=essence0b-20";
	$("a").each(function() {
		let href = $(this).attr("href");
		if (href) {
			if (
				href.indexOf("//amazon.com") !== -1 ||
				href.indexOf("//www.amazon.com") !== -1
			) {
				// Remove Query String from URL
				href = href.split("?")[0];

				// Update Query String
				href += (href.match(/\?/) ? "&" : "?") + queryString;
				$(this).attr("href", href);
			}
		}
	});
}

export default commerceAmazon;
