/**
 * Javascript for Sticky Social Icons
 *
 **/
function articleSocialSticky() {
	const isMobileDevice = window.matchMedia(
		"only screen and (max-width: 760px)"
	).matches;

	const $window = $(window);
	const window_height = $window.innerHeight();

	window.addEventListener(
		"scroll",
		essThrottle(function () {
			let scroll_top = $window.scrollTop();

			if (isMobileDevice) {
				$(".icon-bar.sticky.mobile-only").each(function () {
					let mobilsSocialBar = $(this);
					let article = $(this).closest("article");

					if (
						scroll_top + window_height - 150 <=
							article.offset().top + article.height() &&
						scroll_top + window_height >= article.offset().top
					) {
						mobilsSocialBar.css("right", 0);
					} else {
						mobilsSocialBar.css("right", "-100px");
					}
				});

				// On scroll half transparent, on stop opaque
				$(".icon-bar.sticky.mobile-only").addClass("transparent");
				clearTimeout($.data(this, "scrollCheck"));
				$.data(
					this,
					"scrollCheck",
					setTimeout(function () {
						$(".icon-bar.sticky.mobile-only").removeClass("transparent");
					}, 250)
				);
			}
		})
	);

	// Mobile sticky social share
	if (isMobileDevice) {
		$("body").on("click", ".icon-bar.sticky .expanding-share", function (e) {
			e.preventDefault();
			$(this).toggleClass("share-button-list-active");
			$(this).parent().find(".share-button-list").toggle();
		});
	}

	//calculate top reserve value
	let iFrame = Number($(".__event .custom-entry-header").height()) + 30;
	let iconBar = Number($(".icon-bar.custom-share").height());
	let mainContent = Number($(".__event .text-content").height());
	if (iconBar > mainContent) {
		var margin = iconBar - mainContent + 10;
		$(".event-lineup.set-top").css(`margin-top`, `${margin}px`);
	}
	$(".event-share").css({ top: `${iFrame}px`, position: "absolute" });
}

export default articleSocialSticky;
