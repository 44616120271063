/**
 * All Content post type scripts required should be included here.
 *
 * This is the file that Webpack is compiling into article.[hash].js
 */

import "./sass/article.scss";

import articleScroll from "../../pubstack/assets/js/article/article-scroll";
import articleSocialSticky from "./js/article/article-social-sticky";
import articleVideo from "./js/article/article-video";
import gallery from "./js/article/gallery";
import commerceAmazon from "./js/article/commerce-amazon";

jQuery(function () {
	setTimeout(function () {
		articleScroll();
		articleVideo();
		articleSocialSticky();
		commerceAmazon();
		gallery.init();
	}, 100);
});
