/**
 * Javascript for ArticleVideo
 *
 **/

function articleVideo() {
	if ($(".transcript-btn").length) {
		$(".transcript-btn").on("click", function (e) {
			e.preventDefault();
			$(this)
				.find("span")
				.text(function (_, oldText) {
					return oldText === "Show Transcript"
						? "Hide Transcript"
						: "Show Transcript";
				});
			$(this).find("img").toggleClass("less");
			$(this)
				.parents(".ess_bc_transcript")
				.find(".transcript-description")
				.toggle();
		});
	}

	if ($(".article__content").length) {
		// Expand entry content on read more button click for video article mobile pages
		$("[data-action='toggle-read-more']").on("click", function () {
			$(this).toggleClass("active");
			$(".article__content").slideToggle("fast");
		});
	}

	let resizeTimer;

	$(window).on("resize", function () {
		clearTimeout(resizeTimer);
		resizeTimer = setTimeout(function () {
			if (
				$(
					"article.video .entry-featured-image.wide .bc-hero-video .vjs-pip-container"
				).length
			) {
				CalcArticleHeroHeight();
			}
		}, 50);
	});

	/**
	 * ---- Single Video Article Page ----
	 * 		Handle hero video height
	 * -----------------------------------
	 */
	function CalcArticleHeroHeight() {
		if (window.innerWidth > 1124) {
			target.css("height", (window.innerWidth * 0.75 * 9) / 16);
		} else {
			target.css("height", (window.innerWidth * 9) / 16);
		}
	}
}

export default articleVideo;
