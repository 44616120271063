/**
 * Javascript for Gallery
 *
 **/

import isElementInView from "../../../../pubstack/assets/js/utils/is-in-view";

function Gallery() {
	// article gallery(common-article-sec), commerce cpt(commerce-gallery-item)
	this.selector = ".common-article-sec, .commerce-gallery-item";

	this.init = () => {
		$(window).on("gallery:updatesections", this, this.updatesections);

		// Allow above scroll to scroll before counting pageviews
		const timeout = this.timeout();
		setTimeout(() => {
			$(window).trigger("gallery:updatesections");
			this.registerEvents();
		}, timeout);
	};

	this.updatesections = () => {
		window.galleryItems = $(this.selector);
	};

	this.timeout = () => {
		let timeout = 0;
		if (window.location.hash) {
			const hash = window.location.hash;
			timeout = 2000;

			if (!$(hash).length) {
				return;
			}

			$("html, body").animate(
				{
					scrollTop: $(hash).offset().top,
				},
				1500,
				"swing"
			);
		}

		return timeout;
	};

	this.registerEvents = () => {
		let viewedHashes = [];
		let viewedHashCurr = "";
		let viewedHashLast = "";

		window.addEventListener(
			"scroll",
			essThrottle(() => {
				if (window.galleryItems.length) {
					let viewedHash = null;
					let slideIndex = 0;
					let urlArr = window.location.href.split("#");
					let url = urlArr[0];

					window.galleryItems.each(function (index, el) {
						let hash = $(el).attr("data-hash");
						let commerce_url = $(el).attr("data-url");

						if (hash) {
							if (url === commerce_url && isElementInView(this, "viewed")) {
								viewedHash = hash;
							}
							if (slideIndex > 0) {
								if (isElementInView(this, false)) {
									if ($.inArray(hash, viewedHashes) === -1) {
										viewedHashes.push(hash);
										let post_data = $(this).attr("data-post-info");
										$(window).trigger("analytics:pageView", [hash, post_data]);
									}
								}
							}
							slideIndex++;
						} else {
							slideIndex = 0; // reset each time hash is null
						}
					});
					if (viewedHashCurr !== viewedHash) {
						viewedHashCurr = viewedHash;
					}
					if (viewedHashCurr !== viewedHashLast) {
						if (viewedHash) {
							window.history.replaceState({}, "", url + "#" + viewedHash);
						} else {
							window.history.replaceState({}, "", urlArr[0]);
						}
						viewedHashLast = viewedHashCurr;
					}
				}
			})
		);
	};
}

export default new Gallery();
